import "./index.scss";
import React, { Component } from 'react';
import { SearchBar, Button, Tabs, Empty } from 'antd-mobile';
import axios from "../../util/axios";
import { getDateformat } from "../../util/utils";
import { Link } from "react-router-dom";
class Shopping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [{
                name: '全部',
                status: 0
            }, {
                name: '待审核',
                status: 1
            }, {
                name: '已通过',
                status: 2
            }, {
                name: '已驳回',
                status: 3
            }],
            list: [],
            page: 1,
            merchant_name: '',
            status: 0,
            is_load: !0,
            loading: !0,
        }
    }
    render() {
        return (
            <div className="business-list">
                <div className="header">
                    <div className="search">
                        <SearchBar onChange={e => this.setState({ merchant_name: e })} placeholder='请输入店铺名称' />
                        <Button value={this.state.merchant_name} size="small" color='primary' fill='none' onClick={() => {
                            this.setState({
                                list: [],
                                page: 1,
                                is_load: !0,
                                loading: !0,
                            }, () => {
                                this.getList();
                            })
                        }}>搜索</Button>
                    </div>
                    <Tabs activeKey={this.state.status} style={{
                        '--title-font-size': '14px',
                    }} onChange={key => {
                        this.setState({
                            status: key,
                            list: [],
                            page: 1,
                            is_load: !0,
                            loading: !0,
                        }, () => {
                            console.log(this.state.is_load)
                            this.getList();
                        })
                    }}>
                        {
                            this.state.tabs.map((item, index) => {
                                return (
                                    <Tabs.Tab title={item.name} key={index}>
                                    </Tabs.Tab>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="list">
                    {
                        this.state.list.map((item, index) => {
                            return (
                                <Link to="/businessInfo" key={index}>
                                    <div className="item"  onClick={() => {
                                        localStorage.setItem("businessInfo", JSON.stringify(item))
                                    }}>
                                        <div className="create-time">
                                            <span>提交时间：{getDateformat(item.create_time)}</span>
                                            <span style={{ color: item.status == 1 ? '#999' : item.status == 2 ? '#409EFF' : 'red' }}>{item.status == 1 ? '待审核' : item.status == 2 ? '已通过' : '已驳回'}</span>
                                        </div>
                                        <div className="business-name">{item.merchant_name}</div>
                                        <div className="btn">
                                            <div className="left">
                                                {
                                                    item.status == 3 ? <span>驳回原因：{item.remark}</span> : null
                                                }
                                            </div>
                                            <Button size='small' color='primary'>查看详情</Button>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    {

                        !this.state.list.length ? <Empty style={{ padding: '64px 0' }}
                            imageStyle={{ width: 128 }}
                            description='暂无数据' /> : null
                    }
                </div>
            </div>
        )
    }
    componentDidMount() {
        document.title = "商家列表";
        this.getList();
        window.addEventListener("scroll", this.handelPageScroll, false);
    }
    getList = () => {
        axios.post("/Staff/StoreSettled/list", {
            page: this.state.page,
            rows: 10,
            merchant_name: this.state.merchant_name,
            status: this.state.status
        }).then(res => {
            if (res.code == 0) {
                let list = res.result.list;
                this.setState({
                    is_load:!0
                })
                if (list.length) {
                    this.state.list.push(...list);
                    this.setState({
                        page: this.state.page + 1,
                        list: this.state.list
                    }, () => {
                        if (this.state.list.length >= res.result.total_number) {
                            this.setState({
                                loading: !1,
                                is_load: !1
                            })
                        }
                    })
                }else{
                    this.setState({
                        loading: !1,
                        is_load: !1
                    })
                }
            }
        })
    }
    handelPageScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight + 20 >= scrollHeight) {
            if (this.state.is_load) {
                this.setState({
                    is_load: !1,
                }, () => {
                    this.getList();
                })
            }
        }
    }
}

export default Shopping;