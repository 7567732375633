import { Route, Routes } from "react-router-dom";
import Login from "./views/login";
import AddServiceProvider from "./views/addServiceProvider";
import ScanCode from "./views/scanCode";
import BusinessList from "./views/businessList";
import BusinessInfo from "./views/businessInfo";
function App() {
  return (
    <>
       <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/addServiceProvider" exact element={<AddServiceProvider />} />
        <Route path="/ScanCode" exact element={<ScanCode />} />
        <Route path="/businessList" exact element={<BusinessList />} />
        <Route path="/businessInfo" exact element={<BusinessInfo />} />
      </Routes>
    </>
  );
}

export default App;

