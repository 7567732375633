export const getDateformat = time => {
  let date = new Date(time * 1000);
  let year = date.getFullYear();
  let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return year + '-' + month + '-' + d + ' ' + h + ':' + m + ':' + s;
};

export const inspectPhone = val => {
  let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  if (!myreg.test(val)) {
    return false;
  } else {
    return true;  
  }
};

export const handleLocationPath = () => {
  let path = window.location.href;
  let path_arr = path.split('?');
  let obj = {};
  if (path_arr.length > 1) {
    path_arr = path_arr[1].split('&');
    for (let i in path_arr) {
      let key = path_arr[i].split('=');
      obj[key[0]] = key[1]
    }
  }

  return obj;
}





